import React from 'react';
import { PiStarFourFill } from 'react-icons/pi';
import '../Divider/Divider.css'; 

const Divider = () => {
  return (
    <div className="divider">
      <PiStarFourFill className="small-star" />
      <PiStarFourFill className="big-star" />
      <PiStarFourFill className="small-star" />
    </div>
  );
};

export default Divider;
