import React, { useEffect } from "react";
import "../Raw/Raw.css";
import { Link } from "react-router-dom";
const Raw = () => {
  useEffect(() => {
    const cards = document.querySelectorAll(".card");

    // Apply a sequential delay to each card for animation
    cards.forEach((card, index) => {
      const delay = index * 0.5; // Adjust delay for sequential animation
      card.style.animation = `changeColor 2s ease-in-out ${delay}s infinite, returnToWhite 2s ease-in-out ${
        delay + 2
      }s infinite`;
    });
  }, []);

  const cardTexts = [
    {
      title: "Collagen Production Slows",
      subText:
        "As we age, the body produces less collagen, a key protein that gives skin its structure and elasticity. This reduction leads to sagging, wrinkles, and a less firm appearance over time.",
    },
    {
      title: "Skin Becomes Thinner",
      subText:
        "With aging, the skin’s outer layer, the epidermis, thins out, making it more fragile and prone to injury. Thinner skin also means that blood vessels are more visible, which can cause a translucent look.",
    },
    {
      title: "Loss of Natural Oils",
      subText:
        "The skin's sebaceous glands produce fewer natural oils as we age. This loss of moisture contributes to dry, rough skin, making it more susceptible to fine lines and wrinkles.",
    },
    {
      title: "Decreased Cell Turnover",
      subText:
        "Young skin renews itself every 28 days, but as we age, this process slows down, sometimes taking up to 60 days. Dead skin cells accumulate on the surface, leading to dull, uneven skin tone and texture.",
    },
    {
      title: "Sun Damage Accumulates",
      subText:
        "Years of exposure to UV rays cause cumulative damage, known as photoaging. This manifests as sunspots, rough texture, and uneven pigmentation, particularly on areas frequently exposed, like the face, neck, and hands.",
    },
    {
      title: "Loss of Fat in the Face",
      subText:
        "Aging leads to a natural loss of fat beneath the skin, particularly in the cheeks and around the eyes. This loss of volume can make the skin sag, contributing to a hollow or sunken appearance.",
    },
    {
      title: "Elasticity Reduces",
      subText:
        'Elastin fibers in the skin break down over time, leading to decreased skin elasticity. This reduction means the skin can no longer "snap back" into place as easily, causing fine lines and sagging to become more prominent',
    },
    {
      title: "Environmental Factors",
      subText:
        "Exposure to pollutants, smoke, and even stress accelerates skin aging. These environmental factors create free radicals that damage skin cells, leading to premature signs of aging.",
    },
    {
      title: "Wrinkles Become More Defined",
      subText:
        "Fine lines that may have first appeared in your 30s or 40s deepen over time. Repeated facial expressions,combined with the loss of collagen and elasticity, cause these lines to turn into more pronounced wrinkles.",
    },
    {
      title: " Slower Healing",
      subText:
        "With age, the skin’s ability to heal itself slows down. Wounds, cuts, or even small blemishes take longer to repair, increasing the risk of scarring or infection.",
    },
    {
      title: "Reduced Blood Circulation",
      subText:
        "With age, blood circulation to the skin decreases, reducing oxygen and nutrient delivery to the skin, which contributes to a dull and lackluster complexion.",
    },
  ];

  return (
    <section className="raw">
      <h2>The Authentic Facts </h2>
      <div className="card-grid">
        {cardTexts.map((card, index) => (
          <div key={index} className="card">
            <div className="card-front">
              <h1 className="raw-t2">Fact {index + 1}</h1>
            </div>
            <div className="card-back">
              <h1 className="raw-t">{card.title}</h1>
              <p className="raw-p">{card.subText}</p>
            </div>
          </div>
        ))}
        <Link to="/consultation">
          <div className="card consultation-card">
            <div className="card-front">
              <h1 className="raw-t22">Book Your Consultation</h1>
            </div>
          </div>
        </Link>
      </div>
    </section>
  );
};

export default Raw;
