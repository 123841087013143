import React from 'react';
import '../Chatbox/Chatbox.css';
import { FaFacebook } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";


const Chatbox = () => {
 

 


  const openFacebook = () => {
    const whatsappUrl = 'https://www.facebook.com/people/3E-Skinroute/61565904743428/?mibextid=LQQJ4d';
    window.open(whatsappUrl, '_blank', 'noopener,noreferrer');
  };

  const openInsta = () => {
    const instagramUrl = 'https://www.instagram.com/3e.skinroute/';
    window.open(instagramUrl, '_blank', 'noopener,noreferrer');
  };

  const openLinkedin = () => {
    const linkedinUrl = 'https://www.linkedin.com/in/silkychopra3eskinroute';
    window.open(linkedinUrl, '_blank', 'noopener,noreferrer');
  };


  return (
    <>
      <div className="chat-component">
        <div className="messages">
          {/* Render messages here */}
        </div>
        <FaFacebook className='icon-chat' onClick={openFacebook} />
        <FaInstagramSquare className='icon-chat' onClick={openInsta} />
        <FaLinkedin className='icon-chat' onClick={openLinkedin} />
       
      </div>
    
    </>
  );
};

export default Chatbox;
