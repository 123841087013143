import React from 'react';
import '../Runner2/Runner2.css';

const Runner2 = () => {
  const texts = [
    { text: "Formula First", icon: <img src="/1.webp" alt="Formula First" className='ic-run' /> },
    { text: "Skin Now", icon: <img src="/2.webp" alt="Skin Now" className='ic-run' /> },
    { text: "Planet & Pet Friendly", icon: <img src="/3.webp" alt="Planet & Pet Friendly" className='ic-run' /> },
    { text: "Purposeful", icon: <img src="/4.webp" alt="Purposeful" className='ic-run' /> },
    { text: "Botanical & Biocompatible", icon: <img src="/5.webp" alt="Botanical & Biocompatible" className='ic-run' /> },
    { text: "Cruelty Free", icon: <img src="/6.png" alt="cruelty free" className='ic-run' /> }
  ];

  return (
    <div className="runner-container">
      <div className="runner-content">
        {/* Duplicate the text array for seamless scrolling */}
        {texts.map((item, index) => (
          <span key={index}>
            {item.text} {item.icon}&nbsp;&nbsp;
          </span>
        ))}
        {texts.map((item, index) => (
          <span key={index + texts.length}>
            {item.text} {item.icon}&nbsp;&nbsp;
          </span>
        ))}
      </div>
    </div>
  );
};

export default Runner2;
