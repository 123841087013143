
import React, { useRef, useState } from 'react';
import '../Seller/Seller.css';
import { IoLogoWhatsapp } from "react-icons/io";

import Icard from '../Icard/Icard';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'; // Import arrow icons

const Seller = () => {
  const products = [
    {
      id: 1,
      image: 'pinkt.avif',
      title: 'Pink Rose Cleansing Balm',
      description: 'Discover our gentle Pink Rose Cleansing Balm, oil-based cleanser that change from balm to milk. Infused with Kaolin, Calamine, and French Pink clay for a natural detox, leaving your skin clean, and refreshed.',
      price: 700,
      size: '45gm',
      emi: 'No EMI available',
      rating: 5,
      reviews: 120
    },
    {
      id: 2,
      image: 'gtcover.jpg',
      title: 'Green Tree Cleansing Balm',
      description: 'Experience the power of nature with our Green Tree Cleansing Balm. This gentle, oil-based cleanser blends clay and herbal infusions to detoxify, nourish, and revitalize your skin for a cleaner, softer appearance.',
      price: 700,
      size: '45gm',
      emi: 'EMI available from ₹200/month',
      rating: 4.9,
      reviews: 85
    },
    {
      id: 3,
      image: 'purecover.jpg',
      title: 'Pure Cleanse Micellar Water',
      description: 'Pure Cleanse Micellar Water cleanses, hydrates, and tones your skin. Made with Rose Hydrosol, Ashwagandha, and Green Tea, it offers a gentle cleanse while nourishing and brightening your complexion.',
      price: 750,
      size: '100ml',
      emi: 'EMI available from ₹150/month',
      rating: 5,
      reviews: 85
    },
    {
      id: 4,
      image: 'bloomcover.jpg',
      title: 'Oat Bloom Moisturizer',
      description: 'Our Oat Bloom Moisturizer hydrates, nourishes, and brightens your skin with oat protein and botanical extracts. Designed to soothe sensitive skin, it promotes a radiant complexion while protecting against aging.',
      price: 1850,
      size: '50gm',
      emi: 'No EMI available',
      rating: 5,
      reviews: 120
    },
    {
      id: 5,
      image: 'mooncover2.jpg',
      title: 'Blue Moon Moisturizer',
      description: 'Blue Moon Moisturizer deeply hydrates and rejuvenates your skin with Neroli Hydrosol and Blueberry Extract. This rich formula enhances your skin\'s overall appearance, providing a refreshing boost of moisture.',
      price: 1850,
      size: '50gm',
      emi: 'EMI available from ₹500/month',
      rating: 5,
      reviews: 95
    },
    {
      id: 6,
      image: 'papayacover.jpg',
      title: 'Papaya Scrub With Papain',
      description: 'Packed with papain from papaya, this scrub gently exfoliates to reveal fresh, glowing skin. Shea butter and grapeseed oil ensure deep hydration, leaving your skin soft and radiant without feeling greasy.',
      price: 650,
      size: '30gm',
      price2: 950,
      size2: '50gm',
      emi: 'No EMI available',
      rating: 4.7,
      reviews: 22
    },
    {
      id: 7,
      image: 'abp3.png',
      title: 'AHA+BHA+PHA Facial Scrub',
      description: 'This facial scrub uses AHA, BHA, and PHA to exfoliate and renew your skin. It removes dead cells, unclogs pores, and reduces acne. Natural oils nourish and brighten your skin for a healthier complexion.',
      price: 800,
      size: '30gm',
      price2: 1300,
      size2: '50gm',
      emi: 'No EMI available',
      rating: 5,
      reviews: 72
    },
    {
      id: 8,
      image: 'chocolatecover.jpg',
      title: 'Chocolate Clay Mask',
      description: 'Our Chocolate Clay Mask detoxifies and brightens your skin with chocolate, calamine, and kaolin clays. Infused with rose hydrosol and sunflower oil, it hydrates, soothes, and helps reduce dark spots.',
      price: 750,
      size: '30gm',
      price2: 1250,
      size2: '50gm',
      emi: 'No EMI available',
      rating: 5,
      reviews: 85
    },
    {
      id: 9,
      image: 'sparklecover2.jpg',
      title: 'Sparkle Eyes Revitalizing Eye Cream',
      description: 'Nourish and brighten your eye area with Sparkle Eyes Revitalizing Eye Cream. Rose Hydrosol and Hyaluronic Acid hydrate, while combating signs of aging gently for sensitive skin.',
      price: 1800,
      size: '15ml',
      emi: 'No EMI available',
      rating: 5,
      reviews: 90
    },
    {
      id: 10,
      image: 'mapn2.jpg',
      title: 'Magnesium Ascorbyl Phosphate Face Serum',
      description: 'A potent face serum that delivers a brightening boost with Magnesium Ascorbyl Phosphate and Tranexamic Acid, helping to reduce dark spots and improve overall skin tone for a more radiant complexion.',
      price: 1350,
      size: '30ml',
      emi: 'No EMI available',
      rating: 4.8,
      reviews: 65
    },
    {
      id: 11,
      image: 'mandeliccover.jpg',
      title: 'Mandelic Acid + Salicylic Acid Face Serum',
      description: 'Combining Mandelic and Salicylic Acid, this serum targets acne and uneven skin texture. It helps clear breakouts and smooth skin, revealing a more radiant and refined complexion over time.',
      price: 1350,
      size: '30ml',
      emi: 'No EMI available',
      rating: 5,
      reviews: 72
    },
    {
      id: 12,
      image: 'claritycover.jpg',
      title: 'Clarity Complex Corrector',
      description: 'Clarity Complex Corrector uses cutting-edge ingredients to smooth dark spots and even skin tone.Featuring Polyglyceryl and Nymphaea Alba Flower Extract, delivers visible improvements in clarity.',
      price: 1450,
      size: '15ml',
      price2: 2550,
      size2: '30ml',
      emi: 'No EMI available',
      rating: 5,
      reviews: 85
    },
    {
      id: 13,
      image: 'blisscover2.jpg',
      title: 'Night Bliss Restorative Cream',
      description: 'Night Bliss Restorative Cream rejuvenates your skin while you sleep. Enriched with rose hydrosol and mango butter, it deeply moisturizes and softens skin, leaving it refreshed and supple by morning.',
      price: 2250,
      size: '50gm',

      price2: 1650,
      size2: '30gm',
      emi: 'No EMI available',
      rating: 5,
      reviews: 90
    },
    {
      id: 14,
      image: 'midnightcover2.jpg',
      title: 'Midnight Nourishment Blend',
      description: 'This blend with Squalane and Bakuchiol reduces dark spots and fine lines. Borage seed oil and wheat germ oil help improve skin elasticity and overall health, promoting a radiant, youthful complexion.',
      price: 1450,
      size: '30ml',
      emi: 'No EMI available',
      rating: 5,
      reviews: 85
    }
  ];

  const sliderRef = useRef(null);
  const [isIcardOpen, setIsIcardOpen] = useState(false);

  const scrollLeft = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: -300, // Adjust this value to control the scroll amount
        behavior: 'smooth'
      });
    }
  };

  const scrollRight = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: 300, // Adjust this value to control the scroll amount
        behavior: 'smooth'
      });
    }
  };

 

  const openWhatsapp = () => {
    const whatsappUrl = 'https://api.whatsapp.com/send/?phone=%2B917394011335&text&type=phone_number&app_absent=0';
    window.open(whatsappUrl, '_blank', 'noopener,noreferrer');
  };

  const openIcard = () => {
    setIsIcardOpen(true);
  };

  const closeIcard = () => {
    setIsIcardOpen(false);
  };

  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 >= 0.5 ? 1 : 0;
    const emptyStars = 5 - (fullStars + halfStar);

    return (
      <>
        {[...Array(fullStars)].map((_, index) => (
          <span key={`full-${index}`}>&#9733;</span>
        ))}
        {halfStar === 1 && <span>&#9734;</span>}
        {[...Array(emptyStars)].map((_, index) => (
          <span key={`empty-${index}`}>&#9734;</span>
        ))}
      </>
    );
  };

  return (
    <>
      <div className="parent-component">
        {isIcardOpen && <Icard onClose={closeIcard} />}
      </div>

      <div className="seller-section">
        <h2 className="seller-heading">Best Seller</h2>

        {/* Add left and right arrows */}
        <div className="slider-arrow left-arrow" onClick={scrollLeft}>
          <IoIosArrowBack />
        </div>

        <div className="slider-container" ref={sliderRef}>
          {products.map((product) => (
            <div key={product.id} className="product-card">
              <div className="img-s">
                <img src={product.image} alt={product.title} className="product-image" loading="lazy" />
              </div>
              <h3 className="title-p">{product.title}</h3>
              <p className="description">{product.description}</p>
              <div className={product.price2 && product.size2 ? 'flp' : ''}>
  <div className="fl-pp">
    <div className="price">{`₹ ${product.price} (${product.size})`}</div>
  </div>

  {product.price2 && product.size2 && (
    <div className="fl-pp">
      <div className="price">{`₹ ${product.price2} (${product.size2})`}</div>
    </div>
  )}
</div>


             {/*} <p className="star-rating">
                {renderStars(product.rating)}
              </p>*/}
              <div className="card-btn">
  <button
    className="view-product12"
    onClick={openWhatsapp}
    aria-label="Contact via WhatsApp"
  >
    <IoLogoWhatsapp className="btn-ic" />
  </button>
  <button
    className="view-product12"
    onClick={openIcard}
    aria-label="Consult and Book"
  >
    Consult & Book
  </button>
</div>

            </div>
          ))}
        </div>

        {/* Add right arrow */}
        <div className="slider-arrow right-arrow" onClick={scrollRight}>
          <IoIosArrowForward />
        </div>
      </div>
    </>
  );
};

export default Seller;
