import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate, useLocation, Link } from "react-router-dom";
import "../Shop/Shopdetails.css";
import { IoLogoWhatsapp } from "react-icons/io";
import { FaArrowDown, FaPlus, FaMinus, FaTimes } from "react-icons/fa";
import Icard from "../Icard/Icard";
import Runner2 from "../Runner2/Runner2";

const ShopDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [isIcardOpen, setIsIcardOpen] = useState(false);
  const [expandedDetails, setExpandedDetails] = useState(false);
  const [expandedIngredients, setExpandedIngredients] = useState(false);
  const [expandedFAQ, setExpandedFAQ] = useState(null);
  const [selectedIngredient, setSelectedIngredient] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0); // State for slider

  const product = location.state?.product;

  // useRef to store the interval ID
  const sliderIntervalRef = useRef(null);

  // Function to start the auto-slider
  const startAutoSlider = () => {
    // Clear any existing interval
    if (sliderIntervalRef.current) {
      clearInterval(sliderIntervalRef.current);
    }

    // Set up a new interval
    sliderIntervalRef.current = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === product.slider.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000); // Change image every 5 seconds
  };

  // Initialize the auto-slider when component mounts and when product.slider changes
  useEffect(() => {
    if (product?.slider && product.slider.length > 0) {
      startAutoSlider();
    }

    // Clean up the interval when component unmounts or when product.slider changes
    return () => {
      if (sliderIntervalRef.current) {
        clearInterval(sliderIntervalRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product?.slider]);

  // Function to handle thumbnail clicks
  const handleThumbnailClick = (index) => {
    setCurrentImageIndex(index);
    startAutoSlider(); // Reset the auto-slider interval
  };

  // Rest of your existing functions remain unchanged
  const handleBack = () => {
    navigate(-1);
  };

  const openWhatsapp = () => {
    const whatsappUrl =
      "https://api.whatsapp.com/send/?phone=%2B917394011335&text&type=phone_number&app_absent=0";
    window.open(whatsappUrl, "_blank", "noopener,noreferrer");
  };

  const openIcard = () => {
    setIsIcardOpen(true);
  };

  const closeIcard = () => {
    setIsIcardOpen(false);
  };

  const toggleDetails = () => {
    setExpandedDetails((prev) => !prev);
  };

  const toggleIngredients = () => {
    setExpandedIngredients((prev) => !prev);
  };

  const toggleFAQ = (index) => {
    setExpandedFAQ((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleIngredientClick = (ingredient) => {
    setSelectedIngredient(ingredient);
  };

  const closeOverlay = () => {
    setSelectedIngredient(null);
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Debugging: Check the product object
  console.log("Product Object:", product);

  if (!product) {
    return <p>Product not found!</p>;
  }

  return (
    <>
      <div className="shop-details-container">
        <button className="back-buttona" onClick={handleBack}>
          &#8592; Back to Products
        </button>

        {/* First Section: Image Slider and Product Info */}
        <div className="product-details">
          <div className="left-div">
            <div className="kkk">
              {/* Image Slider */}
              {product.slider && product.slider.length > 0 ? (
                <>
                  <img
                    src={`/${product.slider[currentImageIndex]}`}
                    alt={`${product.title} ${currentImageIndex + 1}`}
                    className="product-image"
                  />
                  {/* Thumbnails */}
                  <div className="thumbnails">
                    {product.slider.map((img, index) => (
                      <img
                        key={index}
                        src={`/${img}`}
                        alt={`${product.title} Thumbnail ${index + 1}`}
                        className={`thumbnail-image ${
                          index === currentImageIndex ? "active" : ""
                        }`}
                        onClick={() => handleThumbnailClick(index)}
                        role="button"
                        tabIndex={0}
                        onKeyPress={(e) => {
                          if (e.key === "Enter" || e.key === " ") {
                            handleThumbnailClick(index);
                          }
                        }}
                        aria-label={`View ${product.title} image ${index + 1}`}
                        style={{
                          border:
                            index === currentImageIndex
                              ? "2px solid #000"
                              : "2px solid transparent",
                          cursor: "pointer",
                          marginRight: "5px",
                          width: "60px",
                          height: "60px",
                          objectFit: "cover",
                        }}
                      />
                    ))}
                  </div>
                </>
              ) : (
                <img
                  src={`/${product.image}`}
                  alt={product.title}
                  className="product-image"
                />
              )}
            </div>
          </div>
          <div className="right-div">
            <h2 className="product-title">{product.title}</h2>
            <h3 className="product-subtitle">{product.title2}</h3>
            <p className="product-description">{product.description}</p>
            <p className="product-description">{product.description2}</p>
            <p className="product-description">
              {product.description3
                ? product.description3.split("|").map((item, index) => {
                    const parts = item.split(":");
                    return (
                      <span key={index}>
                        <strong className="highlight-text">{parts[0]}:</strong>{" "}
                        {parts[1]}
                        <br />
                      </span>
                    );
                  })
                : ""}
            </p>
            <div className="siz">
              <p className="product-price-d">
                <strong>Price:</strong> ₹{product.price}{" "}
                {product.size && `(${product.size})`}
                {product.price2 &&
                  product.size2 &&
                  ` , ₹${product.price2} (${product.size2})`}
              </p>
            </div>
            <Link to="/consultation">
              <button className="consult-button">Is this right for me?</button>
            </Link>
            {/* Collapsible Details Section */}
            <div className="collapse-section">
              <div
                className="collapse-header"
                onClick={toggleDetails}
                aria-expanded={expandedDetails}
                role="button"
                tabIndex={0}
                onKeyPress={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    toggleDetails();
                  }
                }}
              >
                <h4 className="hjj">Product Details</h4>
                {expandedDetails ? <FaMinus /> : <FaPlus />}
              </div>
              {expandedDetails && (
                <div className="collapse-content">
                  <div className="siz">
                    {product.size && (
                      <p>
                        <strong>Size:</strong> {product.size}
                        {product.size2 && ` , ${product.size2}`}
                      </p>
                    )}
                  </div>

                  <p>
                    <strong>Rating:</strong> {product.rating} ⭐
                  </p>
                  <p>
                    <strong>Type:</strong> {product.type}
                  </p>
                  <p>
                    <strong>Skin Mood:</strong> {product.skinMood}
                  </p>
                  {/* Removed redundant Product Type */}
                  <p>
                    {product.incre
                      ? product.incre.split("|").map((item, index) => {
                          const parts = item.split(":");

                          if (parts[0] && parts[1]) {
                            return (
                              <span key={index}>
                                <strong className="incre-str">
                                  {parts[0].trim()}:
                                </strong>{" "}
                                {parts[1].trim()}
                                <br />
                              </span>
                            );
                          }
                          return null; // Skip invalid segments
                        })
                      : "N/A"}
                  </p>
                </div>
              )}
            </div>

            {/* Collapsible Ingredients Section */}
            <div className="collapse-section">
              <div
                className="collapse-header"
                onClick={toggleIngredients}
                aria-expanded={expandedIngredients}
                role="button"
                tabIndex={0}
                onKeyPress={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    toggleIngredients();
                  }
                }}
              >
                <h4 className="hjj">Ingredients & Facts</h4>
                {expandedIngredients ? <FaMinus /> : <FaPlus />}
              </div>
              {expandedIngredients && (
                <div className="collapse-content">
                  <p>
                    <strong className="ing">Ingredients:</strong>{" "}
                    {product.ingredients}
                  </p>
                </div>
              )}
              <div className="butt-div">
                <button
                  className="back-buttonaa"
                  onClick={() => scrollToSection("dou")}
                >
                  Directions of Use
                </button>
                <button
                  className="back-buttonaa"
                  onClick={() => scrollToSection("faq")}
                >
                  Resolve Your Queries
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* New Section: How it Works */}
        <div className="why-how-section">
          <h3 className="section-title">How it Works</h3>
          <p className="how-description">
            {product.how ? (
              product.how.includes(":") ? (
                product.how.split("|").map((item, index) => {
                  const parts = item.split(":");

                  return (
                    <span
                      key={index}
                      style={
                        index === 0
                          ? { marginBottom: "20px", display: "block" }
                          : {}
                      }
                    >
                      <strong className="strong-how">{parts[0].trim()}:</strong>{" "}
                      {/* Keep the colon after strong */}
                      {parts[1] ? <span> {parts[1].trim()}</span> : null}
                      {index < product.how.split("|").length - 1 && <br />}
                    </span>
                  );
                })
              ) : (
                <span>{product.how}</span> // Render normally if no colon present
              )
            ) : (
              "N/A"
            )}
          </p>
        </div>

        {/* Ingredients Images Section */}
        <div className="ingredients-sectionn">
          <h5 className="kyin">Key Ingredients</h5>
          <div className="ingredients-grid">
            {product.ingredientsImages?.map((ingredient, index) => (
              <div
                key={index}
                className="ingredient-item"
                onClick={() => handleIngredientClick(ingredient)}
                role="button"
                tabIndex={0}
                onKeyPress={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    handleIngredientClick(ingredient);
                  }
                }}
                aria-label={`View details of ${ingredient.name}`}
                style={{ cursor: "pointer" }}
              >
                <img
                  src={`/${ingredient.image}`}
                  alt={ingredient.name}
                  className="ingredient-image"
                />
                <p className="ingredient-name">{ingredient.name}</p>
              </div>
            ))}
          </div>
        </div>

        {/* Overlay for Selected Ingredient Image */}
        {selectedIngredient && (
          <div className="overlayy">
            <div className="overlay-contenty">
              <button className="close-buttony" onClick={closeOverlay}>
                <FaTimes size={24} />
              </button>
              <img
                src={`/${selectedIngredient.image}`}
                alt={selectedIngredient.name}
                className="overlay-imagey"
              />
              <p className="overlay-namey">{selectedIngredient.name}</p>
            </div>
          </div>
        )}
        {/* New Section: Directions of Use */}
        <div id="dou" className="directions-section">
          <h3 className="section-title">Directions of Use</h3>
          <p className="direction-description">{product.direction}</p>
        </div>

        {/* FAQ Section */}
        <div id="faq" className="fullfaq">
          <div className="faq-section">
            <h3 className="faq-title">Resolve Your Queries</h3>
            <ul>
              {product.faq?.length > 0 ? (
                product.faq.map((faq, index) => (
                  <li key={index}>
                    <p
                      className="question"
                      onClick={() => toggleFAQ(index)}
                      style={{ cursor: "pointer", fontWeight: "bold" }}
                      aria-expanded={expandedFAQ === index}
                      role="button"
                      tabIndex={0}
                      onKeyPress={(e) => {
                        if (e.key === "Enter" || e.key === " ") {
                          toggleFAQ(index);
                        }
                      }}
                    >
                      Q: {faq.question}
                      <FaArrowDown className="arrow-icon" />
                    </p>
                    {expandedFAQ === index && (
                      <p className="answer">A: {faq.answer}</p>
                    )}
                  </li>
                ))
              ) : (
                <p>No FAQs available for this product.</p>
              )}
            </ul>
          </div>

          {/* Consult & Book Section */}
          <div className="consult-section">
            <h3 className="consult-title">
              Consult with our expert for additional information
            </h3>
            <div className="consult-buttons">
              <button className="whatsapp-button" onClick={openWhatsapp}>
                <IoLogoWhatsapp className="whatsapp-icon" />
                WhatsApp
              </button>
              <button className="consult-book-button" onClick={openIcard}>
                Consult & Book
              </button>
            </div>
          </div>
        </div>

        {/* Icard Overlay */}
        {isIcardOpen && <Icard onClose={closeIcard} />}
        <Runner2 />
      </div>
    </>
  );
};

export default ShopDetails;
