import React, { useState } from 'react';
import '../Products/Category.css';

const Category = ({ onCategoryClick }) => {
  const [selectedCategory, setSelectedCategory] = useState('facialCare');

  const categories = {
    facialCare: [
      { id: 1, name: 'Cleansing', image: 'gtcover.jpg' },
      { id: 2, name: 'Serums', image: 'mandeliccover.jpg' },
      { id: 3, name: 'Exfoliators', image: 'ahacover.jpg' },
      { id: 4, name: "Mask", image: 'chocolatecover.jpg'},
      { id: 5, name: "Spot Corrector",image: 'claritycover.jpg' },
      { id: 6, name: "Face Oil",image: 'midnightcover.jpg' },
      { id: 7, name: 'Moisturizers', image: 'mooncover.jpg' },
     
    ],
    hairCare: [
      
      { id: 8, name: 'Conditioner', image: 'smoothcover.jpg' },
      { id: 9, name: "Serum" , image: 'mapcover.jpg' },     
   
      
    ],
    bodyCare: [
      { id: 10, name: "Eye" ,image: 'sparklecover.jpg'},
      { id: 7, name: 'Moisturizer', image: 'mooncover.jpg' },
      { id: 11, name: 'Exfoliators', image: 'papayacover.jpg' },
    ],
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  const renderCategorySection = (category) => {
    const items = categories[category] || [];

    if (items.length === 0) return null;

    return (
      <div className="category-section-container">
     
        <div className="category-cards">
      
          {items.map((item) => (
            <div
              key={item.id}
              className="category-card"
              onClick={() => onCategoryClick(item.name)}
            >
              <img
                src={item.image}
                alt={item.name}
                className="category-image"
              />
             <div className="category-name">
              <p className='c-name'>{item.name}</p>
            </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <section className="category-section">
     {/*- <img src='bg3.png' className='greenr' alt='rose'/>-*/}
      <img src='bg3.png' className='greenl' alt='rose'/>
      <h2 className='h-c'>Shop by Category</h2>
     
      <div className="toggle-buttons">
        <button
          className={`toggle-btn ${selectedCategory === 'facialCare' ? 'active' : ''}`}
          onClick={() => handleCategoryClick('facialCare')}
        >
          Facial Care
        </button>
        <button
          className={`toggle-btn ${selectedCategory === 'hairCare' ? 'active' : ''}`}
          onClick={() => handleCategoryClick('hairCare')}
        >
          Hair Care
        </button>
        <button
          className={`toggle-btn ${selectedCategory === 'bodyCare' ? 'active' : ''}`}
          onClick={() => handleCategoryClick('bodyCare')}
        >
          Body Care
        </button>
      </div>

      {renderCategorySection(selectedCategory)}
    </section>
  );
};

export default Category;
