import React, { useEffect, useState } from "react";
import "../Modal/Modal.css";

const Modal = ({ show, handleClose, children }) => {
  const [delayedShow, setDelayedShow] = useState(false);

  useEffect(() => {
    const modalMain = document.querySelector(".modal-main");
    
    if (show) {
      // Set a timeout to delay showing the modal for 2 minutes (120000 ms)
      const timer = setTimeout(() => {
        setDelayedShow(true);
        modalMain.style.animationPlayState = "running";
      }, 16300); // 120000 ms = 2 minutes
      
      // Cleanup timeout on component unmount or if show changes
      return () => clearTimeout(timer);
    } else {
      // If show is false, reset the delayedShow state
      setDelayedShow(false);
    }
  }, [show]);

  const showHideClassName = delayedShow ? "modal display-block" : "modal display-none";

  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        <button className="modal-close-button" onClick={handleClose}>
          &times;
        </button>
        {children}
      </section>
    </div>
  );
};

export default Modal;
