// src/components/Blog.js
import React, { useState , useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../Blog/Blog.css";
import { useLocation } from 'react-router-dom';
import Icard from "../Icard/Icard";
// Dummy Blog Data
const blogData = [
  {
    id: "introduction-to-plant-based-skincare",
    category: "Skin Care",
    title: "Introduction to Plant-Based Skincare",
    description:
      "In recent years, the beauty industry has seen a significant shift towards plant-based skincare.",
    image: "b1.jpeg",
    content: `# In recent years, the beauty industry has seen a significant shift towards plant-based skincare .
This movement is not just a passing trend but a response to growing consumer awareness
about the impact of skincare products on both personal health and the environment. Plantbased skincare combines the power of nature with scientific innovation to create products that
are effective, gentle, and sustainable.
Plant-based skincare relies on ingredients derived from plants, including extracts, oils, and
botanicals. These natural components are often rich in vitamins, antioxidants, and other
beneficial compounds that can nourish and protect the skin. Unlike synthetic ingredients, plantbased alternatives are typically more compatible with our skin's natural processes, leading to
better absorption and fewer side effects.

## The Power of Clean Ingredients
Clean ingredients are at the heart of plant-based skincare. But what exactly does "clean" mean
in this context? Clean ingredients are those that are:

- **Free from harmful chemicals and toxins** 
- **Minimally processed** 
- **Transparently sourced** 
- **Safe for long-term use**

Some examples of clean ingredients commonly found in plant-based skincare products include:

- **Aloe vera**: Known for its soothing and hydrating properties 
- **Jojoba oil**: Similar to human sebum, making it excellent for balancing oil production 
- **Green tea extract**: Rich in antioxidants that protect against environmental damage


---

Stay tuned for more insights into effective skincare ingredients!
    `,
  },
  {
    id: "the-benefits-of-clean-ingredients",
    category: "Skin Care",
    title: "The Benefits of Clean Ingredients ",
    description:
      "One of the key advantages of plant-based skincare is its focus on clean ingredients.Clean ingredients are those that are naturally derived...",
    image: "b2.jpeg",
    content: `# Clean ingredients in plant-based skincare products offer several benefits: 

One of the key advantages of plant-based skincare is its focus on clean ingredients. Clean ingredients are
those that are naturally derived, minimally processed, and free from harmful chemicals. These
ingredients are not only better for our skin but also for the environment. 

## Benefits of Clean Ingredients

-  **Reduced risk of irritation**: Natural ingredients are less likely to cause skin irritation or
allergic reactions compared to synthetic chemicals.
- **Environmental friendliness**: Plant-based ingredients are often biodegradable and
sustainably sourced, making them a more eco-friendly choice.
- **Nutrient-rich**: Many plant-based ingredients are packed with vitamins, minerals, and
antioxidants that nourish and protect the skin.
- **Transparency**: Brands that focus on clean ingredients often provide clear information
about their product formulations, allowing consumers to make informed choices.

## The Power of Clean Ingredients

Clean ingredients are at the heart of plant-based skincare. But what exactly does "clean" mean
in this context? Clean ingredients are those that are:
- **Free from harmful chemicals and toxins**
- **Minimally processed**
- **Transparently sourced**
- **Safe for long-term use**

Some examples of clean ingredients commonly found in plant-based skincare products include:
- **Aloe vera**: Known for its soothing and hydrating properties
- **Jojoba oil**: Similar to human sebum, making it excellent for balancing oil production
- **Green tea extract**: Rich in antioxidants that protect against environmental damage

By choosing products with clean ingredients, you're not only benefiting your skin but also
reducing your exposure to potentially harmful substances. Many conventional skincare products
contain parabens, phthalates, and synthetic fragrances, which have been linked to various
health concerns.

---

Stay tuned for more insights into effective skincare ingredients!
    `,
  },
  {
    id: "mild-ingredients-for-sensitive-skin",
    category: "We Care",
    title: "Mild Ingredients for Sensitive Skin ",
    description:
      "For those with sensitive skin, finding suitable skincare products can be a challenge. Skincare with mild ingredients comes to the rescue...",
    image: "b3.jpeg", 
    content: `# For those with sensitive skin, finding skincare products can be a challenge. 
Plant-based skincare with mild ingredients comes to the rescue. Mild ingredients are gentle on the skin,
less likely to cause irritation, and suitable for daily use

## Some examples of mild plant-based ingredients include: 

- **Aloe vera**: Known for its soothing and hydrating properties
- **Chamomile**: Calms and reduces inflammation 
- **Oatmeal**: Moisturizes and protects the skin barrier 
- **Calendula**: Heals and soothes irritated skin 

These mild ingredients can be found in various skincare products, from cleansers to
moisturizers, making it easier for those with sensitive skin to build a complete skincare routine.

## Mild Ingredients: Gentle on Skin, Tough on Results

One of the biggest misconceptions about plant-based skincare is that it's not as effective as its
synthetic counterparts. However, mild ingredients can deliver powerful results without causing
irritation or long-term damage to the skin barrier.

**Mild ingredients work by**:
- **Supporting the skin's natural functions rather than disrupting them**
- **Providing gentle exfoliation without stripping the skin**
- **Offering hydration and nourishment without clogging pores**

For example, lactic acid derived from milk or fruit is a mild alpha-hydroxy acid (AHA) that can
improve skin texture and tone without the harsh effects of stronger chemical exfoliants.
Similarly, plant-based retinol alternatives like bakuchiol offer similar anti-aging benefits without
the potential for irritation associated with traditional retinoids.


---

Explore our range of 3ESkinRoute skincare products and join us in making a positive impact!
    `,
  },
  {
    id: "customized-skincare:tailoring-to-your-needs",
    category: "We Care",
    title: "Customized Skincare: Tailoring to Your Needs ",
    description:
      "One of the most exciting aspects of plant-based skincare is the ability to customize products to suit individual skin needs. With a wide variety...",
    image: "b4.jpeg", 
    content: `# One of the most exciting aspects of plant-based skincare is the ability to customize products to suit individual skin needs.

With a wide variety of plant-based ingredients available, it's possible to create
formulations that address specific skin concerns. 

## Customized skincare can be achieved through:

- **Personalized consultations**: 3ESKINROUTE offers skin assessments to recommend products tailored to
your skin type and concerns. 
- **Mix-and-match approach**: Combining different plant-based products to create a routine that works for
your skin. 
- **Targeted treatments**: Using specific plant extracts to address particular skin issues, such as acne,
hyperpigmentation, or aging. 

One of the most exciting developments in plant-based skincare is the trend towards
customization. As we learn more about the unique needs of different skin types and conditions,
brands are creating products that can be tailored to individual requirements.

- **Customized skincare in the plant-based world might include**:
- **Modular systems where you can mix and match different plant extracts**
- **Personalized formulations based on skin analysis**
- **Adaptable products that respond to changes in your skin or environment**

For instance, some brands offer base serums that can be enhanced with specific plant extracts
to target concerns like acne, hyperpigmentation, or fine lines. This approach allows for a more
precise and effective skincare routine that can evolve with your skin's changing needs.


---

Explore our range of 3ESkinRoute skincare products and join us in making a positive impact!
    `,
  },
  {
    id: "antioxidants:nature's-skin-defenders",
    category: "Skin Care",
    title: "Antioxidants: Nature's Skin Defenders ",
    description:
      "Plant-based skincare products are often filled with antioxidants, which are crucial for maintaining healthy skin...",
    image: "b5.jpeg", 
    content: `# Plant-based skincare products are often filled with antioxidants, which are crucial for maintaining healthy skin.

Antioxidants help protect our skin from free radical damage caused by environmental stressors like
UV radiation and pollution. 


## Some powerful plant-based antioxidants include: 

- **Vitamin C**: Found in citrus fruits, rosehip, and sea buckthorn
- **Vitamin E**:  Present in nuts, seeds, and avocado 
- **Green tea**: Rich in polyphenols 
- **Resveratrol**: Found in grapes and berries

These antioxidants not only protect the skin but also help in reducing signs of aging, improving
skin texture, and promoting a healthy, radiant complexion.





---

Explore our range of 3ESkinRoute skincare products and join us in making a positive impact!
    `,
  },
  {
    id: "antibacterial-and-antifungal-properties",
    category: "Skin Care",
    title: "Antibacterial and Antifungal Properties",
    description:
      "Many plant-based ingredients possess natural antibacterial and antifungal properties, making them effective in treating various skin conditions...",
    image: "b6.jpeg", 
    content: `# Many plant-based ingredients possess natural antibacterial and antifungal properties, making them effective in treating various skin conditions




- **Acne prevention**: By controlling bacteria growth on the skin 
- **Treating fungal infections**: Such as athlete's foot or ringworm
- **Maintaining skin balance**: By promoting a healthy skin microbiome 

## Some plant-based ingredients with antibacterial and antifungal properties include:
- **Tea tree oil**: Known for its potent antimicrobial effects
- **Neem**: Used in traditional medicine for its antiseptic properties
- **Oregano oil**: Contains compounds that fight bacteria and fungi
- **Thyme**: Has natural antimicrobial properties 

## 3ESkinRoute’s Commitment

At 3ESkinRoute, we believe in creating products that not only benefit your skin but also the planet. Our vegan skincare line is crafted with care, ensuring that each product is both effective and ethical.

---

Explore our range of 3ESkinRoute skincare products and join us in making a positive impact!
    `,
  },

  {
    id: "plant-power:from-ancient-times-to-modern-day",
    category: "Skin Care",
    title: "Plant Power: From Ancient Times to Modern Day ",
    description:
      "The use of plants for skincare is not a new concept. In fact, it dates back to ancient times when various civilizations used plant-based remedies for skin health and beauty...",
    image: "b7.jpeg", 
    content: `# The use of plants for skincare is not a new concept.

In fact, it dates back to ancient times when
various civilizations used plant-based remedies for skin health and beauty. From the use of olive
oil in ancient Greece to the application of aloe vera in Egypt, plant-based skincare has a rich
history.

Today, we're seeing a revival of these ancient practices, combined with modern scientific
research. This blend of traditional wisdom and contemporary knowledge has led to the
development of highly effective plant-based skincare products.


 


## Some examples of ancient plant-based skincare practices that are still relevant today include
- **Ayurvedic skincare**: Using herbs and plants like turmeric, neem, and sandalwood
- **Traditional Chinese Medicine**: Incorporating ingredients like ginseng and green tea
- **African botanicals**: Utilizing plants like marula oil and baobab 

These time-tested ingredients are now being formulated into modern skincare products, offering
the best of both worlds.


---

Explore our range of 3ESkinRoute skincare products and join us in making a positive impact!
    `,
  },
  {
    id: "embracing-plant-based-skincare",
    category: "We Care",
    title: "Embracing Plant-Based Skincare",
    description:
      "Plant-based skincare offers a natural, effective, and sustainable approach to skin health With its focus on clean and mild ingredients...",
    image: "b8.jpeg", 
    content: `# Plant-based skincare offers a natural, effective, and sustainable approach to skin health.

With its focus on clean and mild ingredients, customized solutions, and powerful natural compounds,
it's no wonder that more and more people are turning to plant-based products for their skincare
needs.

By incorporating plant-based skincare into your routine, you're not just taking care of your skin;
you're also making a choice that's better for the environment. Whether you're dealing with
sensitive skin, looking for anti-aging solutions, or simply want to maintain healthy skin, there's a
plant-based product out there for you.

Remember, everyone's skin is unique, so it may take some time to find the right combination of
plant-based products that work for you. Don't be afraid to experiment and consult with skincare
professionals to create a routine that suits your specific needs.


# Are you ready to harness the power of plants for your skin?

Start exploring plant-based skincare options today and experience the difference nature can make for your skin health and overall well-being.

Plant-based skincare represents a holistic approach to beauty that aligns with growing
consumer values of health, sustainability, and efficacy. By choosing products with clean, mild,
and customized ingredients, you're not only investing in your skin's health but also supporting a
more ethical and environmentally friendly beauty industry.

As research continues to uncover the benefits of plant-based ingredients, we can expect to see
even more innovation in this space. From advanced extraction techniques that preserve the
potency of botanical actives to new discoveries of powerful plant compounds, the future of
skincare is green, clean, and tailored to you.

Whether you're a skincare novice or a beauty enthusiast, there's never been a better time to
explore the world of plant-based skincare. Your skin—and the planet—will thank you.


---

Explore our range of 3ESkinRoute skincare products and join us in making a positive impact!
    `,
  },
  {
    id: "slows-down-aging",
    category: "Skin Care",
    title: "Slows Down Aging",
    description:
      "Using beauty products that are rich in antioxidants and nourishing ingredients helps to slow down the visible signs of aging....",
    image: "b9.jpeg", 
    content: `# Aging is a natural part of life, but who says we can’t slow it down?

How Beauty Products Help Slow Down Aging?

## Benefits of Anti-Aging Beauty Products

- **Slows Down the Visible Signs of Aging**: Using products designed to combat aging helps reduce the appearance of wrinkles, fine lines, and age spots, giving your skin a smoother and more youthful look over time.
- **Enhances Skin Elasticity**: Anti-aging products boost collagen production and improve skin firmness, which can prevent sagging and give the skin a more lifted appearance.
- **Hydrates and Nourishes the Skin**: Proper hydration is essential for youthful skin. Anti-aging moisturizers provide deep hydration, ensuring your skin stays soft and supple, with fewer dry patches and less irritation.

## 3ESkinRoute’s Commitment

At 3ESkinRoute, we believe in creating products that not only benefit your skin but also the planet. Our vegan skincare line is crafted with care, ensuring that each product is both effective and ethical.

---

Explore our range of 3ESkinRoute skincare products and join us in making a positive impact!
    `,
  },
  {
    id: "lab-stories-1",
    category: "Lab Stories",
   
    video: "pack4.mp4", // Example video URL
    isLabStory: true
    
  },
  {
    id: "lab-stories-2",
    category: "Lab Stories",
   
    video: "pack5.mp4", // Example video URL
    isLabStory: true
  
  },
  {
    id: "lab-stories-3",
    category: "Lab Stories",
  
    video: "pack6.mp4", // Example video URL
    isLabStory: true
   
  },
  {
    id: "lab-stories-4",
    category: "Lab Stories",
    title: "Lab Insights on Plant-Based Ingredients",
    video: "pack7.mp4", // Example video URL
    isLabStory: true
    
  },
  {
    id: "lab-stories-5",
    category: "Lab Stories",
    title: "Lab Insights on Plant-Based Ingredients",
    video: "pack8.mp4", // Example video URL
    isLabStory: true
  
  },
  {
    id: "lab-stories-6",
    category: "Lab Stories",
    title: "Lab Insights on Plant-Based Ingredients",
    video: "pack10.mp4", // Example video URL
    isLabStory: true
  
  },
 

];

const Blog = () => {
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const handleReadMore = (post) => {
    navigate(`/blog/${post.id}`, { state: { post } });
  };
  
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  const filteredPosts =
    selectedCategory === "All"
      ? blogData
      : blogData.filter((post) => post.category === selectedCategory);

      useEffect(() => {
        // Set a timeout to open the modal after 1 minute (60000 ms)
        const timer = setTimeout(() => {
          setIsOpen(true);
        }, 10000); // 1 minute delay
    
        // Cleanup the timeout if the component unmounts or if the timer changes
        return () => clearTimeout(timer);
      }, []); // Empty dependency array to run once on mount
    
      const closeModal = () => {
        setIsOpen(false);
      };
  return (
    <>
     <div>
      {isOpen && <Icard onClose={closeModal} />}
    </div>
    <div className="blog-section">
      {/* Poster Image */}
      <div className="blog-poster">
        <img
          src="bp.jpeg" 
          alt="Blog Poster"
          data-aos="zoom-in"
          className="poster-image"
        />
      </div>

      {/* Toggle Buttons */}
      <div className="blog-toggle">
        <button
          className={selectedCategory === "All" ? "active" : ""}
          onClick={() => setSelectedCategory("All")}
        >
          All
        </button>
        <button
          className={selectedCategory === "Lab Stories" ? "active" : ""}
          onClick={() => setSelectedCategory("Lab Stories")}
        >
          Lab Stories
        </button>
        <button
          className={selectedCategory === "Skin Care" ? "active" : ""}
          onClick={() => setSelectedCategory("Skin Care")}
        >
          Skin Care
        </button>
        <button
          className={selectedCategory === "We Care" ? "active" : ""}
          onClick={() => setSelectedCategory("We Care")}
        >
          We Care
        </button>
      </div>

      {/* Blog Cards */}
      <div className="blog-grid">
  {filteredPosts.map((post) => (
    <div className="blog-card" key={post.id}>
      <div className="blog-card-image">
        {post.video ? (
          <video className="vd" data-aos="flip-up" controls>
            <source src={post.video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <img data-aos="flip-up" src={post.image} alt={post.title} />
        )}
      </div>
      {!post.isLabStory ? (
        <div className="blog-card-content">
          <h4>{post.title}</h4>
          <p>{post.description}</p>
          <span className="read-more" onClick={() => handleReadMore(post)}>
            Read More
          </span>
        </div>
      ) : null}
    </div>
  ))}
</div>


      
    </div>
    </>
  );
};

export default Blog;

