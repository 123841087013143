import React,{useState} from 'react';
import Icard from '../Icard/Icard';
import '../Products/ProductCard.css'; 

import { IoLogoWhatsapp } from "react-icons/io";


const ProductCard = ({ product }) => {
  const [isIcardOpen, setIsIcardOpen] = useState(false);
 

  const openWhatsapp = () => {
    const whatsappUrl = 'https://api.whatsapp.com/send/?phone=%2B917394011335&text&type=phone_number&app_absent=0';
    window.open(whatsappUrl, '_blank', 'noopener,noreferrer');
  };

  // Helper function to render stars
  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 !== 0; // Check if there's a half star
    const stars = [];

    for (let i = 0; i < fullStars; i++) {
      stars.push(<span key={i} className="star">★</span>);
    }

    if (halfStar) {
      stars.push(<span key="half" className="star">☆</span>); // Half star represented as ☆
    }

    return stars;
  };
  const openIcard = () => {
    setIsIcardOpen(true); // Open the Icard
  };

  const closeIcard = () => {
    setIsIcardOpen(false); // Close the Icard
  };

  return (
    <>
   
    <div className="product-cardc">
    <div className='img-s'>
      <img src={product.image} alt={product.title} className="product-image" />
      </div>
      <h3 className='title-p'>{product.title}</h3>
      <p className="descriptionc">{product.description}</p>
      <div className={product.price2 && product.size2 ? 'flp' : ''}>
  <div className="fl-p">
    <div className="price">{`₹ ${product.price} (${product.size})`}</div>
  </div>

  {product.price2 && product.size2 && (
    <div className="fl-p">
      <div className="price">{`₹ ${product.price2} (${product.size2})`}</div>
    </div>
  )}
</div>

      
      <p className='star'>
        {renderStars(product.rating)}
      </p>

      <div className='card-btn'>
  <button
    className="view-product12"
    onClick={openWhatsapp}
    aria-label="Contact via WhatsApp"
  >
    <span className="visually-hidden">Contact via WhatsApp</span>
    <IoLogoWhatsapp className='btn-ic' />
  </button>
  <button
    className="view-product12"
    onClick={openIcard}
    aria-label="Consult and Book"
  >
    Consult & Book
  </button>
</div>

    </div>
    {isIcardOpen && <Icard onClose={closeIcard} />}
    </>
  );
};

export default ProductCard;
